import { useParams } from "react-router-dom";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import PagerHeaderComp from "./PagesHeaderComp";
import ServiceListCard from "./Service_List_Card";
import Slide from "react-reveal/Slide";
import { services } from "./Services/service.utils";

 const whatWeDo: IList[] = services;

export const ServiceDetails = () => {
  const { id } = useParams();
  const selectedIndex = Number(id) < 11 ? Number(id || 1) - 1 : 0; // Adjusted to use index 0 if out of bounds

  const service = whatWeDo[selectedIndex];

  if (!service) {
    return <div className="text-center p-4">Service not found.</div>;
  }

  return (
    <>
      <PagerHeaderComp title={service.title} subTitle={service.subTitle} />

      {/* Service Details */}
      <div className="bg-white py-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-2/3 w-full px-4 py-2">
              {whatWeDo[selectedIndex].content}
            </div>

            <div className="lg:w-1/3 w-full px-4 py-2">
              <Slide up>
                <ServiceListCard />
              </Slide>
            </div>
          </div>
        </div>
      </div>
      {/* Service Details */}
    </>
  );
};

export default ServiceDetails;
