import { Link } from "react-router-dom";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";
import { services } from "./Services/service.utils";

const whatWeDo: IList[] = services;

export const ServiceCard = () => {
  return (
    <>
      {/* Our Service Slide */}
      <Slide up>
        <div className="bg-white text-center py-12 fontJostRegular">
          <div className="container mx-auto px-4">
            <MotionCompGeneral
              showMoveLeft={true}
              showMoveRight={true}
              showRotateLeft={true}
              moveRightImg={`${
                process.env.PUBLIC_URL + "/images/homePage/img-2-1.png"
              }`}
            />
            <p className="text-base font-normal mb-4">
              Our boutique suite of public relations and digital communications
              services assist your business in maintaining a positive image
              whilst building trust with all stakeholders.
            </p>
            <h2 className="text-2xl font-bold fontJostBold">
              At Clear Water, our suite of services is designed to cater to
              every facet of your public relations needs.
              <br /> readers life
            </h2>
          </div>
        </div>
      </Slide>
      {/* Our Service Slide */}

      {/* Service Slide */}
      <div className="bg-white text-center py-12">
        <MotionCompGeneral showMoveLeft={true} showMoveRight={true} />
        <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {whatWeDo.map((item: IList, index: number) => (
            <Slide up key={index}>
              <div className="p-4">
                <div className="rounded-xl shadow-lg text-left">
                  <img
                    className="rounded-xl mx-auto mb-4 object-cover"
                    src={item.image}
                    alt="service"
                    style={{
                      width: "100%",
                      height: "250px",
                    }}
                  />{" "}
                  <div className="p-4">
                    <h4 className="text-2xl font-bold fontJostBold mb-2">
                      {item.title}
                    </h4>

                    <p className="text-gray-700 mb-2">{item.caption}</p>
                    <Link
                      to={item.link || "/"}
                      className="text-sky-500 hover:underline"
                    >
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </Slide>
          ))}
        </div>
      </div>
      {/* Service Slide */}
    </>
  );
};

export default ServiceCard;
