import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

export const ServiceTwitterTrendCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL +
              "/images/services/service_twitter_trend.jpg"
            }`}
            alt="twitter_trend_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          In today’s fast-paced digital landscape, trending on Twitter can
          skyrocket your brand’s visibility and engagement. A well-executed
          Twitter trend campaign can boost your influence and connect you with a
          broader audience.
        </p>
        <p className="fontJostRegular w3-medium">
          At ClearWater, we specialize in orchestrating impactful Twitter Trend
          Takeovers that create buzz around your brand. Our team leverages data
          insights, hashtag strategies, and influencer collaborations to ensure
          your brand captures the spotlight.
        </p>
        <p className="fontJostRegular w3-medium">
          Our Twitter Trend Takeover services include:
          <ul>
            <li>Hashtag Strategy & Campaign Creation</li>
            <li>Influencer Partnerships</li>
            <li>Real-Time Trend Monitoring & Optimization</li>
            <li>Performance Analytics & Reporting</li>
          </ul>
        </p>
        <p className="fontJostRegular w3-medium">
          Whether you're launching a new product or amplifying a special event,
          we’ll help you design a Twitter campaign that gets people talking,
          sharing, and engaging with your brand.
        </p>

        {/* Call to Action Section */}
        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Ready to create a buzz on Twitter? Contact us today to learn how our
            Twitter Trend Takeover service can help your brand trend and reach
            new heights of visibility.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950 mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get a Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceTwitterTrendCard;
