import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

export const ServiceSocialFootPrintCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL +
              "/images/services/service_social_media_expansion.jpg"
            }`}
            alt="social_footprint_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          Expanding your social media footprint is essential for increasing
          brand visibility and engaging with a wider audience. A strong social
          media presence can amplify your message and boost brand recognition.
        </p>
        <p className="fontJostRegular w3-medium">
          At ClearWater, we specialize in building and expanding social media
          footprints through strategic content, targeted outreach, and community
          engagement. Our approach ensures that your brand reaches its maximum
          potential across all platforms.
        </p>
        <p className="fontJostRegular w3-medium">
          Our Social Media Footprint Expansion services include:
          <ul>
            <li>Platform-Specific Strategy Development</li>
            <li>Content Creation & Optimization</li>
            <li>Follower Growth & Engagement Tactics</li>
            <li>Analytics & Performance Tracking</li>
          </ul>
        </p>
        <p className="fontJostRegular w3-medium">
          Whether you're looking to expand on Instagram, LinkedIn, or any other
          platform, we tailor our strategies to your specific goals, ensuring
          you grow your audience and build stronger connections.
        </p>

        {/* Call to Action Section */}
        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Ready to take your social media presence to the next level? Contact
            us today to learn how our Social Media Footprint Expansion service
            can help you engage more effectively with your audience.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950 mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get a Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceSocialFootPrintCard;
