import { Link } from "react-router-dom";
import { IList } from "../../../utils/generalType";
import Slide from "react-reveal/Slide";
import { services } from "./Services/service.utils";

const whatWeDo: IList[] =services;

export const ServiceListCard = () => {
  return (
    <div className="p-4">
      <div className="bg-white rounded-xl p-6 shadow-lg">
        <Slide up>
          <h3 className="text-xl font-bold mb-4">Our Services</h3>
        </Slide>
        {whatWeDo.map((item: IList, index: number) => (
          <Slide up key={index}>
            <div className="border-t border-gray-200 py-2">
              <Link
                to={item.link || "/"}
                className="text-lg text-sky-950 hover:underline"
              >
                {item.title}
              </Link>
            </div>
          </Slide>
        ))}
      </div>
    </div>
  );
};

export default ServiceListCard;
