import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

export const ServiceWebDesignCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL + "/images/services/service_web.jpg"
            }`}
            alt="service_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          At ClearWater, our Website Design service is dedicated to creating
          websites that not only look stunning but also perform exceptionally.
          We understand that your website is often the first point of contact
          with your audience, and we aim to make that first impression count.
        </p>
        <p className="fontJostRegular w3-medium">
          Our team of expert designers and developers works closely with you to
          understand your goals, brand identity, and target audience. We then
          translate that understanding into a custom-designed website that
          reflects your vision and aligns with your business objectives. From
          sleek portfolios to dynamic e-commerce platforms, we create websites
          that are not only visually appealing but also user-friendly and
          optimized for performance.
        </p>
        <p className="fontJostRegular w3-medium">
          We don't just focus on aesthetics; we ensure that your website is
          built with the latest technologies and best practices in mind. This
          includes responsive design, fast loading times, and seamless
          integration with various tools and platforms to enhance your website's
          functionality and user experience.
        </p>
        <p className="fontJostRegular w3-medium">
          Our approach is collaborative and client-focused. We keep you involved
          at every step of the design and development process to ensure that the
          final product not only meets but exceeds your expectations. Whether
          you're launching a new brand or revamping an existing one, ClearWater
          is here to help you stand out in the digital landscape.
        </p>

        {/* Call to Action Section */}
        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Ready to transform your vision into a stunning online presence?
            Contact us today and let's create a website that elevates your brand
            and engages your audience.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950 mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceWebDesignCard;
