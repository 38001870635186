import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

export const ServiceExecutiveBrandingCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL +
              "/images/services/service_executive_branding.jpg"
            }`}
            alt="executive_branding_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          Your personal brand as an executive can be a powerful tool for driving
          business success. Our Executive Branding services focus on crafting
          and enhancing your leadership presence to elevate your influence and
          impact.
        </p>
        <p className="fontJostRegular w3-medium">
          At ClearWater, we work with leaders to strategically position their
          brand, ensuring it aligns with both personal values and business
          objectives. Let us help you build a personal brand that reflects your
          expertise, leadership style, and vision.
        </p>
        <p className="fontJostRegular w3-medium">
          Our Executive Branding services include:
          <ul>
            <li>Personal Brand Strategy & Positioning</li>
            <li>Executive Thought Leadership Development</li>
            <li>Online Presence Optimization</li>
            <li>Media Training & Public Relations Support</li>
          </ul>
        </p>
        <p className="fontJostRegular w3-medium">
          By curating a strong executive brand, you can inspire your team,
          influence key stakeholders, and enhance your industry reputation. Let
          ClearWater guide you through the process of building a compelling
          executive brand.
        </p>

        {/* Call to Action Section */}
        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Take your leadership brand to the next level. Contact us today and
            discover how our Executive Branding services can elevate your
            professional presence and influence.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950 mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get a Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceExecutiveBrandingCard;
