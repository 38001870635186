import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";



export const ServiceSEOCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL + "/images/services/service_strategy.jpg"
            }`}
            alt="service_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          In today's digital landscape, visibility is everything. A strong
          online presence is crucial to gaining a competitive edge. With
          ClearWater's Search Engine Optimization (SEO) services, we help your
          brand climb search engine rankings and increase organic traffic,
          driving more qualified visitors to your website.
        </p>
        <p className="fontJostRegular w3-medium">
          Our expert SEO team develops data-driven strategies tailored to your
          unique business needs. We start with comprehensive keyword research,
          uncovering the terms your target audience is searching for. From
          there, we optimize your website's structure and content to ensure it's
          fully aligned with search engine algorithms.
        </p>
        <p className="fontJostRegular w3-medium">
          But we don't stop there. SEO is an ongoing process, and we
          continuously monitor your website's performance to make adjustments
          and improvements as needed. We focus on both on-page and off-page SEO,
          including backlink building, content optimization, and technical SEO
          to ensure long-term success.
        </p>
        <p className="fontJostRegular w3-medium">
          Our SEO solutions also include local SEO, helping businesses capture
          relevant traffic from their local markets. Whether you're a small
          business looking to dominate locally or a larger enterprise aiming for
          a national or global reach, our SEO services are designed to get
          results.
        </p>

        <p className="fontJostRegular w3-medium">
          Partner with ClearWater, and let us help you improve your search
          engine rankings, drive organic growth, and increase your brand's
          visibility online. From detailed reporting to tailored
          recommendations, we ensure you're always in the loop on your SEO
          progress.
        </p>

        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Ready to boost your website's performance and rank higher on search
            engines? Get in touch with us now to harness the power of
            ClearWater's SEO services, and take your online presence to new
            heights.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950  mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get a Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceSEOCard;
