import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { sampleApiCall } from "../../../apiservice/sampleUsage/sample";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import Slide from "react-reveal/Slide";
import emailjs from "@emailjs/browser";

export const ContactComp = () => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  // Use to collect Input change Change
  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values: any) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setFormLoading(true);

    emailjs
      .send(
        "service_Lamphill",
        "template_Owner",
        {
          message: payLoad?.message,
          fullName: payLoad?.fullName,
          email: payLoad?.email,
          subject: payLoad?.subject,
          phoneNumber: payLoad?.phone,
        },
        {
          publicKey: "bPhK6vjI5kNa_T6Ot",
        }
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Your Form Have been sent");
          setFormLoading(false);
          sendReplyMessage();
        },
        (error) => {
          console.log("FAILED...", error);
          alert(error);

          setFormLoading(false);
        }
      );
  };

  const sendReplyMessage = () => {
    emailjs
      .send(
        "service_Lamphill",
        "template_sender",
        {
          email: payLoad?.email,
        },
        {
          publicKey: "bPhK6vjI5kNa_T6Ot",
        }
      )
      .then(
        (response) => {
          console.log("SUCCESS! Reply Mail", response.status, response.text);
          setFormLoading(false);
        },
        (error) => {
          console.log("FAILED... Reply Mail", error);
          setFormLoading(false);
        }
      );
  };

  // A custom hook to save form data
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);

      alert("Your Form Have been sent");
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      alert(result.data?.response?.data?.message || result.errorMsg);
      //Handle Error Here
    }
  };

  return (
    <>
      {/* Contact Us */}
      <div className="bg-sky-950 pt-12">
        <div className="container mx-auto px-4 fontJostRegular">
          <Slide up>
            <div className="space-y-4 py-8 text-center">
              <h1 className="text-5xl font-bold fontJostBold text-white">
                Let's Talk
              </h1>
              <p className="text-xl font-medium text-white">
                Bring your media deals to us.
              </p>
            </div>
          </Slide>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <Slide left>
              <div>
                <img
                  className="max-w-4/5 w-100"
                  alt="heroImage"
                  src={`${
                    process.env.PUBLIC_URL + "/images/contact/lets_talk.png"
                  }`}
                />
              </div>
            </Slide>
            <Slide up>
              <div className={"pb-4"}>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-wrap -mx-2 mb-4">
                    <div className="w-full lg:w-1/2 px-2 mb-4 lg:mb-0">
                      <input
                        required
                        name="fullName"
                        value={payLoad?.fullName || ""}
                        onChange={handleInputChange}
                        className="w-full h-12 px-3 py-2 border rounded-lg text-white bg-black bg-opacity-25 border-sky-100"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="w-full lg:w-1/2 px-2">
                      <input
                        required
                        name="phone"
                        value={payLoad?.phone || ""}
                        onChange={handleInputChange}
                        className="w-full h-12 px-3 py-2 border rounded-lg text-white bg-black bg-opacity-25 border-sky-100"
                        placeholder="Phone Number"
                        type="tel"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <input
                      required
                      name="email"
                      value={payLoad?.email || ""}
                      onChange={handleInputChange}
                      className="w-full h-12 px-3 py-2 border rounded-lg text-white bg-black bg-opacity-25 border-sky-100"
                      placeholder="Email"
                      type="email"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      required
                      name="subject"
                      value={payLoad?.subject || ""}
                      onChange={handleInputChange}
                      className="w-full h-12 px-3 py-2 border rounded-lg text-white bg-black bg-opacity-25 border-sky-100"
                      placeholder="Subject"
                    />
                  </div>
                  <div className="mb-4">
                    <textarea
                      required
                      name="message"
                      value={payLoad?.message || ""}
                      onChange={handleInputChange}
                      className="w-full h-60 px-3 py-2 border rounded-lg text-white bg-black bg-opacity-25 border-sky-100"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div>
                    <button
                      className="bg-sky-800 border-white border text-white px-6 py-2 rounded-lg"
                      disabled={formLoading}
                    >
                      {!formLoading ? "Send Message" : <LoadingOutlined />}
                    </button>
                  </div>
                </form>
              </div>
            </Slide>
          </div>
        </div>
      </div>
      {/* Contact Us */}
    </>
  );
};

export default ContactComp;
