import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";
import ServiceCard from "../sharedLandingcomponents/Service_Card_Comp";

const ServicesPage = () => {
  return (
    <div className="bg-white min-h-[400px]">
      <PagerHeaderComp title="Our Services" />
      <div className="container mx-auto px-4 py-8">
        <ServiceCard />
      </div>
    </div>
  );
};

export default ServicesPage;
