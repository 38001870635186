import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";



export const ServiceSMACard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL +
              "/images/services/service_Social_media_advocacy.jpg"
            }`}
            alt="service_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          Social media is a powerful tool to engage with your audience, shape
          perceptions, and amplify your brand’s message. At ClearWater, we
          understand that authentic advocacy is more than just posting
          content—it's about building genuine relationships with your audience
          and encouraging them to champion your brand.
        </p>
        <p className="fontJostRegular w3-medium">
          Our Social Media Advocacy service is designed to help your brand
          establish a strong online presence and create meaningful connections
          with your followers. We develop strategies that go beyond
          surface-level engagement, encouraging your audience to become active
          participants in your brand’s growth by sharing their experiences and
          spreading your message.
        </p>
        <h2 className="fontJostBold w3-large">
          Empowering Your Audience to Become Advocates
        </h2>
        <p className="fontJostRegular w3-medium">
          We craft compelling content that resonates with your audience,
          ensuring your brand’s voice is heard across platforms. Whether it’s
          through creative campaigns, user-generated content, or influencer
          partnerships, we help amplify your brand’s message and turn your
          followers into loyal advocates.
        </p>
        <h2 className="fontJostBold w3-large">
          Engaging, Building, and Sustaining Communities
        </h2>
        <p className="fontJostRegular w3-medium">
          Our team helps you build vibrant online communities where your
          audience feels connected to your brand. Through active engagement and
          consistent communication, we foster loyalty and trust. From targeted
          social media campaigns to real-time engagement, we ensure your brand
          remains at the forefront of conversations that matter.
        </p>
        <p className="fontJostRegular w3-medium">
          Partner with ClearWater to leverage social media’s full potential and
          build lasting relationships that benefit your brand in the long term.
          Together, we’ll create advocacy campaigns that resonate with your
          audience, grow your brand’s reach, and drive sustained engagement.
        </p>

        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Ready to turn your audience into brand advocates? Contact us today
            and let ClearWater transform your social media presence. We’ll help
            you create lasting impact and engagement that drives your brand
            forward.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950  mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get a Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceSMACard;
