import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";

const testimony: IList[] = [
  {
    title: "Jane Ann",
    subTitle: "CEO",
    caption:
      "Working with ClearWater was a game-changer for our brand. Their approach to reputation management helped us connect with our audience on a deeper level, and the results were beyond our expectations. Their team's dedication to our success was evident in every step of the process. ClearWater is not just a service provider but a true partner in building a stronger, more authentic brand.",
    image: `${
      process.env.PUBLIC_URL +
      "/images/testimony/testi-15-copyright-300x300.jpg"
    }`,
  },
  {
    title: "Helen Matthew",
    subTitle: "Marketing Manager",
    caption:
      "Choosing ClearWater was one of the best decisions we made for our company. Their team's professionalism and commitment to our success were evident from day one. They took the time to understand our unique needs and tailored their strategies accordingly. Thanks to ClearWater, we've seen a significant improvement in our reputation and brand visibility. We couldn't be happier with the results!",
    image: `${
      process.env.PUBLIC_URL +
      "/images/testimony/testi-17-copyright-300x300.jpg"
    }`,
  },
];

export const TestimonyComp = () => {
  return (
    <>
      {/* Testimony */}
      <div className="bg-white py-12">
        <Slide up>
          <div className="hidden md:block">
            <MotionCompGeneral
              showMoveLeft={true}
              showMoveRight={true}
              showRotateRight={true}
              rotateRightImg={`${
                process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
              }`}
            />
          </div>
        </Slide>
        <Slide up>
          <div className="container mx-auto px-4 fontJostRegular">
            <Swiper
              modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
              navigation
              pagination={{ clickable: true }}
              spaceBetween={20}
              slidesPerView={1}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                840: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
              }}
            >
              {testimony.map((item: IList, index: number) => (
                <SwiperSlide key={index}>
                  <div className="text-center">
                    <img
                      className="mt-4 mb-4 rounded-full"
                      src={item.image}
                      alt="testimonial"
                      style={{ maxWidth: "100%", width: "100px" }}
                    />
                    <h2 className="font-bold fontJostBold text-sky-950 text-2xl">
                      {item.title}
                    </h2>
                    <p className="font-medium text-base">{item.subTitle}</p>
                    <p className="font-regular text-base">{item.caption}</p>
                  </div>
                  <div className="grid" style={{ height: "60px" }}>
                    <br />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Slide>
      </div>
      {/* Testimony */}
    </>
  );
};

export default TestimonyComp;
