import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

export const HeroComp = () => {
  return (
    <>
      {/* Hero Section */}
      <div className="pt-20 w-full bg-sky-100">
        <div className="container mx-auto px-6 py-3 fontJostRegular">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-7/12 md:w-7/12">
              <Slide left>
                <h2 className="text-3xl lg:text-5xl  font-bold leading-tight fontJostBold">
                  Expert online reputation management for a clean, trusted
                  online presence
                </h2>
                <p className="mt-12 text-base">
                  Our boutique suite of public relations and digital
                  communications services assist your business in maintaining a
                  positive image whilst building trust with all stakeholders.
                  <br />
                  Our transparent, results-driven approach ensures your brand's
                  reputation stays protected and accurately reflects the values
                  you stand for. <br />
                  Whether it's managing reviews, curating content, or mitigating
                  PR crises, we provide cutting-edge solutions for a squeaky
                  clean digital presence and sane & equitable social media
                  engagement.
                </p>

                <p className="mt-12">
                  <Link
                    className="btn bg-sky-950 px-8 py-5 text-white font-semibold rounded-3xl shadow-lg mb-4 mr-4"
                    to={"/contact"}
                  >
                    <span>GET STARTED </span>
                  </Link>
                  <Link
                    className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg mb-4"
                    to={"/landing/about"}
                  >
                    <span> ABOUT US</span>
                  </Link>
                </p>
              </Slide>
            </div>

            <div className="w-full lg:w-5/12 md:w-5/12 flex justify-center">
              <Slide up>
                <img
                  className="max-w-4/5 w-100 pt-8 md:pt-0"
                  alt="heroImage"
                  src={`${
                    process.env.PUBLIC_URL + "/images/hero/hero-img-min.png"
                  }`}
                />
              </Slide>
            </div>
          </div>
        </div>
      </div>
      {/* End Hero Section */}
    </>
  );
};

export default HeroComp;
