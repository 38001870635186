import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

export const ServiceInfluencerMarketingCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL +
              "/images/services/service_influencer_marketing.jpg"
            }`}
            alt="influencer_marketing_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          In today’s digital landscape, influencer marketing is one of the most
          effective ways to build authentic connections with your target
          audience. By leveraging trusted voices, you can drive meaningful
          engagement and brand loyalty.
        </p>
        <p className="fontJostRegular w3-medium">
          At ClearWater, we specialize in crafting influencer campaigns that
          resonate with your audience. From identifying the right influencers to
          executing targeted campaigns, we ensure your brand is authentically
          represented and strategically positioned.
        </p>
        <p className="fontJostRegular w3-medium">
          Our Influencer Marketing services include:
          <ul>
            <li>Influencer Identification & Outreach</li>
            <li>Campaign Strategy & Management</li>
            <li>Content Creation & Brand Integration</li>
            <li>Performance Tracking & Analytics</li>
          </ul>
        </p>
        <p className="fontJostRegular w3-medium">
          Partner with influencers who align with your brand values and goals.
          Whether it’s increasing brand awareness, driving sales, or growing
          your community, our team will craft a data-driven influencer marketing
          strategy that delivers results.
        </p>

        {/* Call to Action Section */}
        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Amplify your brand's reach with influencer marketing. Contact us
            today to explore how we can build impactful campaigns that connect
            you with your audience in meaningful ways.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950 mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get a Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceInfluencerMarketingCard;
