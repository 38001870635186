import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import Slide from "react-reveal/Slide";

type IPagerHeaderComp = {
  title?: string;
  subTitle?: string;
};

export const PagerHeaderComp: React.FC<IPagerHeaderComp> = ({
  title,
  subTitle,
}) => {
  return (
    <Slide up>
      {/* Header Title */}
      <div className="bg-sky-950 text-white text-center py-24 fontJostRegular">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-2 fontJostBold">{title}</h2>
          <p className="text-lg font-medium">{subTitle}</p>
        </div>
      </div>
      {/* Header Title */}
    </Slide>
  );
};

export default PagerHeaderComp;
