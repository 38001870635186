import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";



export const ServicePRCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL + "/images/services/service_market.jpg"
            }`}
            alt="service_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          A great product or service alone won't captivate your target audience.
          Building a powerful brand presence that resonates and leaves a lasting
          impression is essential for standing out from the competition and
          fostering lasting customer loyalty.
        </p>
        <p className="fontJostRegular w3-medium">
          At ClearWater, we understand that brand building is both an art and a
          science. It's not about guesswork or generic strategies; it's about
          crafting a unique narrative that speaks directly to your audience's
          needs, values, and aspirations.
        </p>
        <p className="fontJostRegular w3-medium">
          Our Reputation & Brand Boosting service is driven by data-backed
          insights and a deep understanding of consumer behavior. We conduct
          thorough market research, analyze industry trends, and dive into your
          target audience's psychographics, ensuring that every aspect of your
          brand strategy resonates with the right people at the right time.
        </p>
        <p className="fontJostRegular w3-medium">
          Through strategic storytelling and innovative marketing campaigns,
          we'll help you create a compelling brand narrative that distinguishes
          you from the competition. We utilize a multi-channel approach,
          seamlessly integrating traditional and digital platforms to amplify
          your brand's voice and establish a strong presence across various
          touchpoints.
        </p>
        <p className="fontJostRegular w3-medium">
          But building a strong brand isn't just about crafting a narrative;
          it's about cultivating lasting connections with your audience. Our
          team of experts will work closely with you to develop a robust
          reputation management strategy, proactively monitoring and addressing
          any potential threats or negative sentiment to ensure your brand's
          image remains impeccable.
        </p>
        <p className="fontJostRegular w3-medium">
          Our Reputation & Brand Boosting experts are ready to embark on a
          transformative journey with you, utilizing data-driven insights,
          strategic storytelling, and innovative marketing campaigns to elevate
          your brand to new heights.
          <ul>
            <li>Take the First Step Towards Brand Excellence</li>
            <li>Schedule a comprehensive brand audit and consultation</li>
            <li>Receive a customized brand strategy tailored to your needs</li>
            <li>Gain access to our suite of reputation management tools</li>
          </ul>
        </p>
        <p className="fontJostRegular w3-medium">
          With ClearWater as your partner, you'll benefit from our unwavering
          commitment to excellence, a deep understanding of consumer behavior,
          and a proven track record of delivering tangible results for our
          clients.
        </p>

        {/* Call to Action Section */}
        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Don't settle for a mediocre brand presence. Contact us today and let
            our Reputation & Brand Boosting service propel your brand toward
            lasting success, captivating your audience, and leaving an indelible
            mark in your industry.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950  mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get a Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServicePRCard;
