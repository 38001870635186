import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

export const ServiceMobileAppCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL + "/images/services/service_mobile.jpg"
            }`}
            alt="service_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          At ClearWater, our Mobile App Development service is designed to bring
          your vision to life with a mobile app that is both functional and
          beautiful. We understand the importance of delivering a seamless
          experience that keeps your users engaged and satisfied.
        </p>
        <p className="fontJostRegular w3-medium">
          Our team of expert developers and designers works closely with you to
          understand your unique needs and objectives. We use this knowledge to
          create a custom mobile app that not only meets your requirements but
          also exceeds your expectations. Whether you need a simple app or a
          complex, multi-functional platform, we have the expertise to deliver
          it.
        </p>
        <p className="fontJostRegular w3-medium">
          We take a user-centered approach to design, ensuring that your app is
          intuitive and easy to navigate. Our focus is on creating a smooth and
          enjoyable user experience, with fast load times, responsive designs,
          and seamless integration with other tools and platforms.
        </p>
        <p className="fontJostRegular w3-medium">
          From initial concept to final launch, we're with you every step of the
          way. Our collaborative approach ensures that you're involved in every
          decision, and our dedication to quality means that your app will be
          built using the latest technologies and best practices.
        </p>

        {/* Call to Action Section */}
        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Ready to transform your idea into a high-performance mobile app?
            Contact us today and let's create an app that not only meets your
            needs but also delights your users.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950 mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceMobileAppCard;
