import "./PrivacyPolicy.css";
import MotionCompGeneral from "../sharedLandingcomponents/Motion_Comp";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white min-h-screen">
      <PagerHeaderComp title="CLEARWATERSPRIVACY POLICY" />

      {/* Content */}
      <div className="bg-gray-50 py-8">
        {/* Motions for Traffic */}
        <div className="hidden md:block">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${process.env.PUBLIC_URL}/images/homePage/spec-el-2.png`}
          />
        </div>
        <div className="container mx-auto px-4 py-8">
          <div className="text-base text-gray-700 leading-relaxed">
            <p className="font-light mb-4">Last updated 04-04-2024</p>
            <p className="mb-4">
              This privacy policy for ClearWater ("we," "us," or "our") describes
              how and why we might collect, store, use, and/or share ("process")
              your information when you use our services ("Services").
            </p>
            <p className="mb-4">
              Reading this privacy notice will help you understand your privacy
              rights and choices. If you do not agree with our policies and
              practices, please do not use our Services.
            </p>

            <h2 className="font-semibold text-lg mb-4">
              SUMMARY OF KEY POINTS
            </h2>
            <p className="mb-4">
              This summary provides key points from our privacy policy. Still,
              you can find more details about any of these topics by clicking
              the link following each key point or using our table of contents
              below to find the section you are looking for.
            </p>

            <ul className="list-disc pl-5 mb-4">
              <li className="font-light mb-2">
                <strong>What personal information do we process?</strong>
                When you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use.
              </li>
              <li className="font-light mb-2">
                <strong>
                  Do we process any sensitive personal information?
                </strong>
                We do not process sensitive personal information.
              </li>
              <li className="font-light mb-2">
                <strong>
                  Do we receive any information from third parties?
                </strong>
                We may receive information from public databases, marketing
                partners, social media platforms, and other outside sources.
              </li>
              <li className="font-light mb-2">
                <strong>How do we process your information?</strong>
                We process your information to provide, improve, and administer
                our Services, communicate with you for security and fraud
                prevention, and comply with the law. We may also process your
                information for other purposes with your consent.
              </li>
              <li className="font-light mb-2">
                <strong>
                  In what situations and with which parties do we share personal
                  information?
                </strong>
                We may share information in specific situations and with
                specific third parties.
              </li>
              <li className="font-light mb-2">
                <strong>What are your rights?</strong>
                Depending on where you are located geographically, the
                applicable privacy law may mean you have certain rights
                regarding your personal information.
              </li>
              <li className="font-light mb-2">
                <strong>How do you exercise your rights?</strong>
                The easiest way is to submit a data subject access request or
                contact us. We will consider and act upon any request in
                accordance with applicable data protection laws.
              </li>
            </ul>

            <p className="mb-4">
              Want to learn more about what we do with any information we
              collect? Review the privacy policy in full.
            </p>

            <ol className="list-decimal pl-5 mb-4">
              <li className="font-semibold mb-2">
                WHAT INFORMATION DO WE COLLECT?
              </li>
              <p className="mb-4">
                We collect personal information that you voluntarily provide us
                when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                participate in activities on the Services, or otherwise contact
                us.
              </p>
              <p className="mb-4">
                <strong>Sensitive Information</strong> We do not process
                sensitive information.
              </p>
              <p className="mb-4">
                All personal information you provide must be true, complete, and
                accurate, and you must notify us of any changes to such personal
                information.
              </p>
              <p className="mb-4">
                <strong>Information automatically collected</strong>
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information).
                Still, it may include device and usage information, such as your
                IP address, browser and device characteristics, operating
                system, language preferences, referring URLs, device name,
                country, location, information about how and when you use our
                Services, and other technical information. This information is
                primarily needed to maintain the security and operation of our
                Services, and for our internal analytics and reporting purposes.
              </p>
              <p className="mb-4">
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </p>

              <li className="font-semibold mb-2">
                HOW DO WE PROCESS YOUR INFORMATION?
              </li>
              <p className="mb-4">
                We process your personal information for various reasons,
                depending on how you interact with our Services to provide,
                improve, and administer our Services, communicate with you for
                security and fraud prevention, and comply with the law. We may
                also process your information for other purposes with your
                consent.
              </p>

              <li className="font-semibold mb-2">
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </li>
              <p className="mb-4">
                We may need to share your personal information in the following
                situations:
              </p>
              <ul className="list-disc pl-5 mb-4">
                <li className="font-light mb-2">
                  <strong>Business Transfers</strong>: We may share or transfer
                  your information in connection with or during negotiations of
                  any merger, sale of company assets, financing, or acquisition
                  of all or a portion of our business to another company.
                </li>
                <li className="font-light mb-2">
                  <strong>Affiliates</strong>: We may share your information
                  with our affiliates, in which case we will require them to
                  honor this privacy notice. Affiliates include our parent
                  company and any subsidiaries, joint venture partners, or other
                  companies we control or are under common control.
                </li>
                <li className="font-light mb-2">
                  <strong>Business Partners</strong>: We may share your
                  information with our business partners to offer certain
                  products, services, or promotions.
                </li>
              </ul>

              <li className="font-semibold mb-2">
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </li>
              <p className="mb-4">
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Our Cookie
                Notice provides specific information about how we use such
                technologies and how you can refuse certain cookies.
              </p>

              <li className="font-semibold mb-2">
                HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </li>
              <p className="mb-4">
                Our Services allow you to register and log in using your
                third-party social media account details (like your Facebook or
                Twitter logins). Wherever you choose to do this, we will receive
                certain profile information about you from your social media
                provider. The profile information we receive may vary depending
                on the social media provider concerned but will often include
                your name, email address, friends list, profile picture, and
                other information you choose to make public on such a social
                media platform.
              </p>
              <p className="mb-4">
                We will use the information we receive only for the purposes
                described in this privacy notice or otherwise made clear to you
                on the relevant Services.
              </p>
              <p className="mb-4">
                Please note that we do not control and are not responsible for
                other uses of your personal information by your third-party
                social media provider. We recommend reviewing their privacy
                notice to understand how they collect, use, and share your
                personal information and how you can set your privacy
                preferences on their sites and apps.
              </p>

              <li className="font-semibold mb-2">
                IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
              </li>
              <p className="mb-4">
                Our servers are located in. If you access our Services from
                outside, please be aware that your information may be
                transferred to, stored, and processed by us in our facilities
                and by third parties with whom we may share your personal
                information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                INFORMATION?" above) in other countries.
              </p>
              <p className="mb-4">
                If you are a resident in the European Economic Area (EEA), then
                your personal information is transferred outside the EEA.
              </p>

              <li className="font-semibold mb-2">
                HOW LONG DO WE KEEP YOUR INFORMATION?
              </li>
              <p className="mb-4">
                We may keep your personal information for as long as necessary
                to fulfill the purposes outlined in this privacy notice unless a
                longer retention period is required or permitted by law.
              </p>

              <li className="font-semibold mb-2">
                HOW DO WE KEEP YOUR INFORMATION SAFE?
              </li>
              <p className="mb-4">
                We aim to protect your personal information through a system of
                organizational and technical security measures. Despite our
                safeguards, however, no electronic transmission or storage of
                information can be guaranteed to be 100% secure, so we cannot
                guarantee absolute security.
              </p>

              <li className="font-semibold mb-2">
                WHAT ARE YOUR PRIVACY RIGHTS?
              </li>
              <p className="mb-4">
                Depending on where you are located geographically, the
                applicable privacy law may mean you have certain rights
                regarding your personal information.
              </p>

              <li className="font-semibold mb-2">
                CONTROLS FOR DO-NOT-TRACK FEATURES
              </li>
              <p className="mb-4">
                Most web browsers and some mobile operating systems include a
                Do-Not-Track ("DNT") feature or setting you can activate to
                signal that you do not want to be tracked. At this time, no
                uniform technology standard for recognizing and implementing DNT
                signals has been finalized.
              </p>

              <li className="font-semibold mb-2">
                DO WE MAKE UPDATES TO THIS PRIVACY NOTICE?
              </li>
              <p className="mb-4">
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
              </p>

              <li className="font-semibold mb-2">
                HOW CAN YOU CONTACT US ABOUT THIS PRIVACY NOTICE?
              </li>
              <p className="mb-4">
                If you have questions or comments about this notice, you may
                contact us at:
                <br />
                ClearWater <br />
                1234 Sample Address <br />
                Sample City, SC 12345 <br />
                Email: outreach@clearwater-pr.com
              </p>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
