import { IList } from "../../../../utils/generalType";
import ServiceCyberSecurityCard from "./Service_Cyber";
import { ServiceExecutiveBrandingCard } from "./Service_Executives_Branding";
import ServiceInfluencerMarketingCard from "./Service_Influencer_Marketing";
import ServiceMobileAppCard from "./Service_Mobile";
import ServicePRCard from "./Service_PR";
import ServiceSEOCard from "./Service_SEO";
import ServiceSMACard from "./Service_SMA";
import ServiceSocialFootPrintCard from "./Service_Social_Foot_Print";
import ServiceTwitterTrendCard from "./Service_Twitter_Trend";
import ServiceWebDesignCard from "./Service_Web";

export const services: IList[] = [
  {
    title: "Public Relations",
    subTitle: "Elevate Your Brand with Reputation & Brand Boosting",
    caption:
      "At ClearWater, we create brand strategies that connect with your audience and leave a lasting impact. Partner with us to elevate your brand to new heights!",
    image: `${process.env.PUBLIC_URL + "/images/services/service_market.jpg"}`,
    content: <ServicePRCard />,
    link: "/landing/services/1/Public_Relations_Management",
  },
  {
    title: "Search Engine Optimization",
    subTitle: "Boost Your Online Presence with Advanced SEO Techniques",
    caption:
      "Enhance your website’s visibility with data-driven SEO strategies that increase organic traffic and rankings. Let's improve your digital presence!",
    image: `${
      process.env.PUBLIC_URL + "/images/services/service_strategy.jpg"
    }`,
    content: <ServiceSEOCard />,
    link: "/landing/services/2/SEO",
  },
  {
    title: "Social Media Advocacy",
    subTitle: "Harness the Power of Social Media to Amplify Your Brand",
    caption:
      "Grow your online presence with effective social media campaigns that engage followers and promote your brand. Let's turn followers into brand advocates!",
    image: `${process.env.PUBLIC_URL + "/images/services/service_Social_media_advocacy.jpg"}`,
    content: <ServiceSMACard />,
    link: "/landing/services/3/social_Media_Advocacy",
  },
  {
    title: "Adaptive Web Design",
    subTitle: "Transforming Your Vision into a Stunning Online Presence",
    caption:
      "We craft exceptional websites that resonate with your audience and deliver results. Let’s build a unique online experience for your brand.",
    image: `${process.env.PUBLIC_URL + "/images/services/service_web.jpg"}`,
    content: <ServiceWebDesignCard />,
    link: "/landing/services/4/Website_Design",
  },
  {
    title: "Mobile Apps Development",
    subTitle: "Transforming Ideas into Intuitive, High-Performance Mobile Apps",
    caption:
      "We create high-performance mobile apps that connect with users and deliver results. Let’s bring your mobile app idea to life.",
    image: `${process.env.PUBLIC_URL + "/images/services/service_mobile.jpg"}`,
    content: <ServiceMobileAppCard />,
    link: "/landing/services/5/MobileApp_Development",
  },
  {
    title: "Cyber Security",
    subTitle: "Protect Your Business with Advanced Cyber Security Solutions",
    caption:
      "Safeguard your business with cutting-edge cyber security strategies tailored to your needs. Let’s protect your data and assets.",
    image: `${
      process.env.PUBLIC_URL + "/images/services/service_cyber_security.jpg"
    }`,
    content: <ServiceCyberSecurityCard />,
    link: "/landing/services/6/Cyber_Security",
  },
  {
    title: "Social Media Footprint Expansion",
    subTitle: "Broaden Your Online Reach with Strategic Social Media Growth",
    caption:
      "Expand your digital footprint and connect with new audiences through targeted social media growth strategies.",
    image: `${
      process.env.PUBLIC_URL +
      "/images/services/service_social_media_expansion.jpg"
    }`,
    content: <ServiceSocialFootPrintCard />,
    link: "/landing/services/7/Social_Media_Foot_Print_Expansion",
  },
  {
    title: "Twitter Trend Takeover",
    subTitle:
      "Dominate the Twitter Conversation with Our Trend Takeover Service",
    caption:
      "Create buzz on Twitter and become a top trend with our specialized trend takeover service. Let’s get you trending!",
    image: `${
      process.env.PUBLIC_URL + "/images/services/service_twitter_trend.jpg"
    }`,
    content: <ServiceTwitterTrendCard />,
    link: "/landing/services/8/Twitter_Trend_Take_Over",
  },
  {
    title: "Influencer Marketing",
    subTitle: "Leverage Influencers to Amplify Your Brand's Reach",
    caption:
      "Work with top influencers to promote your brand and engage new audiences through authentic campaigns. Let’s boost your reach.",
    image: `${
      process.env.PUBLIC_URL +
      "/images/services/service_influencer_marketing.jpg"
    }`,
    content: <ServiceInfluencerMarketingCard />,
    link: "/landing/services/9/Influencer_Marketing",
  },
  {
    title: "Executive Branding",
    subTitle:
      "Build a Powerful Personal Brand with Our Executive Branding Service",
    caption:
      "Strengthen your personal brand and enhance your leadership profile with strategic executive branding services.",
    image: `${
      process.env.PUBLIC_URL + "/images/services/service_executive_branding.jpg"
    }`,
    content: <ServiceExecutiveBrandingCard />,
    link: "/landing/services/10/Executives_Branding",
  },
];
