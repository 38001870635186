import { Link } from "react-router-dom";
import { services } from "../../../pages/landingpages/sharedLandingcomponents/Services/service.utils";

const Footer = () => {
  return (
    <div className="bg-sky-100 pt-12 fontJostRegular">
      <div className="container mx-auto px-6 py-3">
        <div className="py-12 min-h-[200px] flex flex-wrap">
          <div className="w-full lg:w-1/3 md:w-1/3 p-2">
            <span className="logoTextFooter">
              <img
                className="h-10 w-2/5"
                src={`${process.env.PUBLIC_URL + "/images/logo.png"}`}
                alt="Logo"
              />
            </span>

            <p className="pt-5 text-base">
              Clear Water PR offers advanced online reputation management (ORM)
              services, that conduct brand listening on social media platforms,
              as well as search engine audits, in order to ensure your image
              stays clean as a whistle.
            </p>

            <p className="pt-5 flex items-center">
              <span className="ml-3">
                <a
                  href="https://www.instagram.com/clearwatercomms?igsh=MTI4OWF3eTMxdHRvNQ=="
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    className="w-9"
                    alt="Instagram logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/homePage/instagram_icon.webp"
                    }
                  />
                </a>
              </span>
              <span className="ml-3">
                <a
                  href="https://www.linkedin.com/company/clearwatercommunications/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    className="w-9"
                    alt="LinkedIn logo"
                    src={
                      process.env.PUBLIC_URL + "/images/homePage/linkedin.svg"
                    }
                  />
                </a>
              </span>
            </p>
          </div>

          <div className="w-full lg:w-2/3 md:w-2/3 flex flex-wrap">
            <div className="w-full lg:w-1/2 md:w-1/2 p-2">
              <h3 className="fontJostBold font-bold text-2xl capitalize py-1">
                Services
              </h3>
              {services.map((service, index) => (
                <Link
                  key={index}
                  className="block py-1 text-base"
                  to={service.link || ""}
                >
                  {service.title}
                </Link>
              ))}
            </div>

            <div className="w-full lg:w-1/2 md:w-1/2 p-2">
              <h3 className="fontJostBold font-bold text-2xl capitalize py-1">
                Contacts
              </h3>
              <Link className="flex py-1 text-base" to="/">
                <span className="w-1/3">Phone:</span>
                <span className="w-2/3"> 667-755-1285 </span>
              </Link>
              <Link className="flex py-1 text-base" to="/">
                <span className="w-1/3">Email:</span>
                <span className="w-2/3">outreach@clearwater-pr.com</span>
              </Link>
              <Link className="flex py-1 text-base" to="/">
                <span className="w-1/3">Address:</span>
                <span className="w-2/3">Maryland, USA</span>
              </Link>

              <h3 className="fontJostBold font-bold text-2xl mt-5 capitalize">
                Terms
              </h3>
              <Link
                className="block py-1 text-base"
                to="/landing/terms_and_condition"
              >
                Terms And Condition
              </Link>
              <Link
                className="block py-1 text-base"
                to="/landing/privacy_policy"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>

        <div className="pt-12 pb-5 min-h-[60px] border-t">
          <p className="copyRightText text-center">
            © Copyright 2024 © All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
